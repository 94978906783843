import { BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function LogoDinTidning({ size, title }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 212 52"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}

      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#333333"
          stroke="none"
          d="m0,.73h11.71c7.42,0,9.24,3.2,9.24,9.09v32.73c0,6.11-1.82,9.09-9.02,9.09H0V.73Zm8.95,42.47h1.53c1.09,0,1.53-.51,1.53-1.6V10.91c0-1.24-.58-1.75-1.67-1.75h-1.38v34.04Z"
        />
        <polygon
          fill="#333333"
          stroke="none"
          points="24.22 51.63 26.11 15.31 32.51 15.31 34.4 51.63 24.22 51.63"
        />
        <rect
          fill="#333333"
          stroke="none"
          x="25.09"
          y=".73"
          width="8.44"
          height="9.31"
        />
        <g>
          <path
            fill="#333333"
            stroke="none"
            d="m46.18,23.05c.15,2.47.29,10.25.29,13.31v15.27h-8.07V.73h8.22l6.11,28.65c-.15-2.62-.36-10.25-.36-13.31V.73h8.07v50.91h-8.14l-6.11-28.58Z"
          />
          <path
            fill="#333333"
            stroke="none"
            d="m77.81,51.63h-8.94V9.16h-5.16V.73h19.27v8.44h-5.16v42.47Z"
          />
          <path
            fill="#333333"
            stroke="none"
            d="m85.88.73h8.95v50.91h-8.95V.73Z"
          />
          <path
            fill="#333333"
            stroke="none"
            d="m99.26.73h11.71c7.42,0,9.24,3.2,9.24,9.09v32.73c0,6.11-1.82,9.09-9.02,9.09h-11.93V.73Zm8.94,42.47h1.53c1.09,0,1.53-.51,1.53-1.6V10.91c0-1.24-.58-1.75-1.67-1.75h-1.38v34.04Z"
          />
          <path
            fill="#333333"
            stroke="none"
            d="m132.35,23.05c.14,2.47.29,10.25.29,13.31v15.27h-8.07V.73h8.22l6.11,28.65c-.15-2.62-.36-10.25-.36-13.31V.73h8.07v50.91h-8.15l-6.11-28.58Z"
          />
          <path
            fill="#333333"
            stroke="none"
            d="m151.33.73h8.95v50.91h-8.95V.73Z"
          />
          <path
            fill="#333333"
            stroke="none"
            d="m172.85,23.05c.15,2.47.29,10.25.29,13.31v15.27h-8.07V.73h8.22l6.11,28.65c-.15-2.62-.36-10.25-.36-13.31V.73h8.07v50.91h-8.14l-6.11-28.58Z"
          />
          <path
            fill="#333333"
            stroke="none"
            d="m201.87,21.74h10.4v20.8c0,5.6-2.33,9.82-10.4,9.82s-10.4-4.22-10.4-9.82V9.82c0-5.6,2.33-9.82,10.4-9.82s10.4,4.22,10.4,9.82v7.42h-8.87v-7.56c0-.95-.51-1.6-1.53-1.6s-1.53.65-1.53,1.6v32.8c0,1.16.51,1.82,1.53,1.82s1.53-.65,1.53-1.82v-12.43h-1.53v-8.29Z"
          />
        </g>
      </g>
    </BaseSvgStyle>
  );
}

export default LogoDinTidning;
